<template>
    <div>
        <Breadcrumb titulo="RELATORIOS" :items="itensBreadcrumb"/>
        <div class="container-fluid py-4">
            <div class="row">
                <div class="col-12">

                    <tabela-relatorios class="consulta-atendimentos-page-tabela"
                                       :items="atendimentosConsulta == null ? [] : atendimentosConsulta.FiltroAtendimento"
                                       :quantidadesItems="atendimentosConsulta != null ? (atendimentosConsulta.Quantidades - 1) : 0"
                                       :loading="loadingAtendimentos"
                                       :atendimentoTabulacoes="atendimentosConsulta != null ? atendimentosConsulta.AtendimentoTabulacoes : []"
                                       :filtrosIniciais="filtrosIniciais"
                                       :enumOrigem="enumOrigem"
                                       :enumStatus="enumStatus"
                                       :enumUsuarios="enumUsuarios"
                                       :selectTabulacao="listaSelectTabulacao" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import TabelaRelatorios from '@/components/tabelas/TabelaRelatorios.vue'
    import { mapActions, mapGetters } from 'vuex';
    import AtendimentoServices from '@/assets/js/services/AtendimentoServices'

    export default {
        name: 'RelatoriosPage',

        components: {
            Breadcrumb,
            TabelaRelatorios,
        },

        data: function () {
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-search',
                        texto: 'Consultas',
                        link: '/consultas'
                    },
                    {
                        id: '2',
						classe: 'fas fa-file-alt',
                        texto: 'Relatorios',
                        link: ''
                    },
                ],
                atendimentosConsulta: null,
                itensTabulacao: [],
                loadingAtendimentos: false,
                filtrosIniciais: { dataInicial: '', dataFinal: '' },
                colorText: '#000'
            }
        },

        computed: {
            ...mapGetters({
                enumOrigem: 'atendimento/enumOrigem',
                enumStatus: 'atendimento/enumStatus',
                enumUsuarios: 'usuarios/listaUsuarios',
                listaSelectTabulacao: 'atendimento/listaSelectTabulacao'
            })
        },

        created() {
            this.buscaEnumsAtendimentos()
            this.buscaUsuarios()
            this.buscaSetores()
            this.filtrosIniciais = {
                dataInicial: (new Date()).toLocaleDateString().split('/').reverse().join('-'),
                dataFinal: (new Date()).toLocaleDateString().split('/').reverse().join('-'),
            }
            // this.buscarAtendimentosComFiltros(this.filtrosIniciais)
        },

        methods: {
            ...mapActions({
                buscaEnumsAtendimentos: 'atendimento/buscaEnums',
                buscaUsuarios: 'usuarios/buscaUsuarios',
                buscaSetores: 'setores/buscaSetores',
            }),
            async buscarAtendimentosComFiltros(filtros, selecionado = 1) {
                this.loadingAtendimentos = true
                try {

                    this.atendimentosConsulta = await AtendimentoServices.getAtendimentoTabulacao(
                        filtros.dataInicial,
                        filtros.dataFinal,
                        filtros.origem,
                        filtros.status,
                        filtros.atendente,
                        filtros.listaTabulacaoPaiId,
                        filtros.pesquisadoPorProtocolo,
                        ((selecionado - 1) * 10)
                    )

                    if (this.atendimentosConsulta == null) {
                        this.makeToast()
                    }

                } catch (e) {
                    console.log('erro na consulta de atendimentos', e)
                } finally {
                    this.loadingAtendimentos = false
                }
            },
            makeToast(variant = null) {
                this.$bvToast.toast('Dados filtrados nao foram encontrados!', {
                    title: `Problema de busca`,
                    variant: 'danger',
                    autoHideDelay: 3000,
                    solid: true,
                    noCloseButton: true
                })
                this.loadingAtendimentos = false
            }

        }
    }
</script>

<style scoped>
    .color_produto {
        background-color: #a1c9ff;
        color: #000 !important;
    }

    .consulta-atendimentos-page-tabela {
        height: calc(100vh - 180px);
    }
</style>