<script>
  import { Bar } from 'vue-chartjs'

  export default {
    extends: Bar,
    props:{     
      labelsDataHorizontal:{
        type:Array||Object,
        defaults:null
      },
      labelsDataVertical:{
         type:Array||Object,
         defaults:null
      },
      isResponsive:{
        type:Boolean,
        default:true
      },
      labelData:{
        type:String,
        default:'Total'
      }
    },
    data() {
      return {
        chartData: {
          labels: this.labelsDataHorizontal,
          datasets: [{
            label:this.labelData,
            borderWidth: 1,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'],
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
             pointBorderColor: '#2554FF',
             data: this.labelsDataVertical
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: true
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: this.isResponsive,
          maintainAspectRatio: false
        }
      }
    },  
    mounted() {
        
      this.renderChart(this.chartData, this.options)
    }

  }
</script>