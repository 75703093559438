<template>
    <div>
        <b-modal id="MensagensSProtocolo"
                 ref="MensagensSProtocolo"
                 title="Mensagem"
                 centered size="lg"
                 hide-footer>

            <b-row>
                <b-col>
                    <div v-html="mensagem" id="cx_mensagem"></div>
                </b-col>
            </b-row>

            <div class="w-100 py-3 text-right">
                <span class="btn_cancelar_msg" @click="fecharPopup">Fechar</span>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                nome: '',
            }
        },
        props: {
            mensagem: ''
        },
        methods: {
            mostrarPopup() {
                this.$refs['MensagensSProtocolo'].show();
            },
            fecharPopup() {
                this.$refs['MensagensSProtocolo'].hide();
            },
        },
    }
</script>

<style scoped>
    .erro-input {
        color: red;
        font-size: 12px;
    }

    .btn_cancelar_msg {
        cursor: pointer;
        border-bottom: 2px solid;
        padding: 10px;
    }

    #cx_mensagem {
        padding: 5px 5px;
        background-color: #e8e8e8
    }
</style>